import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "faq"
    }}>{`FAQ`}</h1>
    <h2 {...{
      "id": "type-infer"
    }}>{`Type Infer`}</h2>
    <p>{`When you are writing `}<inlineCode parentName="p">{`Effect/SSREffect`}</inlineCode>{` logic, if the `}<inlineCode parentName="p">{`Effect`}</inlineCode>{` emit an `}<inlineCode parentName="p">{`Action`}</inlineCode>{` which infer from itself, or some `}<inlineCode parentName="p">{`Effects`}</inlineCode>{` circular dependents the others, `}<inlineCode parentName="p">{`Type infer`}</inlineCode>{` in TypeScript may fail.`}</p>
    <p><a parentName="p" {...{
        "href": "https://codesandbox.io/s/sigi-faq-type-infer-3lwum"
      }}>{`Example in CodeSandbox`}</a></p>
    <iframe src="https://codesandbox.io/embed/sigi-faq-type-infer-3lwum?fontsize=14&hidenavigation=1&module=%2Fsrc%2Fapp.module.ts&theme=dark" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} title="sigi-faq-type-infer" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { EffectModule, Module, ImmerReducer, Effect } from "@sigi/core";
import { Draft } from "immer";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export interface CountState {
  count: number;
}

@Module("Count")
export class CountModule extends EffectModule<CountState> {
  defaultState = {
    count: 0,
  };

  @ImmerReducer()
  set(state: Draft<CountState>, payload: number) {
    state.count = state.count + payload;
  }

  @Effect()
  addLeastFive(payload$: Observable<number>) {
    return payload$.pipe(
      map((payload) => {
        if (payload < 5) {
          return this.getActions().addLeastFive(payload + 1);
        }
        return this.getActions().set(payload);
      })
    );
  }
}
`}</code></pre>
    <p>{`In this scenario, you need give the `}<inlineCode parentName="p">{`Effect`}</inlineCode>{` a `}<inlineCode parentName="p">{`Return Type`}</inlineCode>{` `}<strong parentName="p">{`explicit`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`- import { EffectModule, Module, ImmerReducer, Effect } from '@sigi/core'
+ import { EffectModule, Module, ImmerReducer, Effect, Action } from '@sigi/core'

- addLeastFive(payload$: Observable<number>) {
+ addLeastFive(payload$: Observable<number>): Observable<Action> {
`}</code></pre>
    <h2 {...{
      "id": "effect-end-callback"
    }}><strong parentName="h2">{`Effect`}</strong>{` end callback`}</h2>
    <p>{`There are some cases you may need run `}<inlineCode parentName="p">{`callback`}</inlineCode>{` after `}<strong parentName="p">{`Effect`}</strong>{` end:`}</p>
    <h3 {...{
      "id": "show-message-after-successfailure"
    }}>{`Show message after Success/Failure`}</h3>
    <p>{`Depends on the API which show message, we could use `}<strong parentName="p">{`Data driven`}</strong>{`/`}<strong parentName="p">{`Side effect`}</strong>{` ways to implement this requirement:`}</p>
    <p><a parentName="p" {...{
        "href": "https://codesandbox.io/s/sigi-faq-effects-callback-f403k"
      }}>{`CodeSandbox effects callback side effect`}</a></p>
    <iframe src="https://codesandbox.io/embed/sigi-faq-effects-callback-f403k?fontsize=14&hidenavigation=1&module=%2Fsrc%2Fapp.module.ts&theme=dark" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} title="sigi-faq-effects-callback-side-effect" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Module, EffectModule, Reducer, Effect, Action } from "@sigi/core";
import { Observable, of } from "rxjs";
import {
  exhaustMap,
  takeUntil,
  map,
  tap,
  startWith,
  catchError,
} from "rxjs/operators";
import { message } from "antd";

import { HttpClient } from "./http.service";

interface AppState {
  list: string[] | null | Error;
}

@Module("App")
export class AppModule extends EffectModule<AppState> {
  defaultState: AppState = {
    list: [],
  };

  constructor(private readonly httpClient: HttpClient) {
    super();
  }

  @Reducer()
  cancel(state: AppState) {
    return { ...state, ...this.defaultState };
  }

  @Reducer()
  setList(state: AppState, list: AppState["list"]) {
    return { ...state, list };
  }

  @Effect()
  fetchList(payload$: Observable<void>): Observable<Action> {
    return payload$.pipe(
      exhaustMap(() => {
        return this.httpClient.get(\`/resources\`).pipe(
          tap(
            () => {
              message.success("Got response");
            },
            (e) => {
              message.error(e.message);
            }
          ),
          map((response) => this.getActions().setList(response)),
          catchError((e) => of(this.getActions().setList(e))),
          startWith(this.getActions().setList(null)),
          takeUntil(this.getAction$().cancel)
        );
      })
    );
  }
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://codesandbox.io/s/sigi-faq-effects-pure-state-msbz4"
      }}>{`CodeSandbox effects callback state driven`}</a></p>
    <iframe src="https://codesandbox.io/embed/sigi-faq-effects-pure-state-msbz4?fontsize=14&hidenavigation=1&module=%2Fsrc%2Fapp.module.ts&theme=dark" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} title="sigi-faq-effects-callback-side-effect" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import "reflect-metadata";
import "antd/dist/antd.css";
import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import { useModule } from "@sigi/react";
import { initDevtool } from "@sigi/devtool";
import { Modal } from "antd";

import { AppModule } from "./app.module";

function App() {
  const [{ list }, dispatcher] = useModule(AppModule);
  const [modalVisible, setModalVisible] = useState(true);
  const onFetchList = useCallback(() => {
    setModalVisible(true);
    dispatcher.fetchList();
  }, [dispatcher, setModalVisible]);
  const onClose = useCallback(() => {
    setModalVisible(false);
  }, [setModalVisible]);

  const loading = !list ? <div>loading</div> : null;

  const title =
    list instanceof Error ? (
      <>
        <Modal
          title="fail"
          visible={modalVisible}
          onOk={onClose}
          onCancel={onClose}
        >
          <p>{list.message}</p>
        </Modal>
        <h1>{list.message}</h1>
      </>
    ) : (
      <h1>Hello CodeSandbox</h1>
    );

  const listNodes = Array.isArray(list)
    ? list.map((value) => <li key={value}>{value}</li>)
    : null;
  return (
    <div>
      {title}
      <button onClick={onFetchList}>fetchList</button>
      <button onClick={dispatcher.cancel}>cancel</button>
      {loading}
      <ul>{listNodes}</ul>
    </div>
  );
}

const rootElement = document.getElementById("app");
render(<App />, rootElement);

initDevtool();
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      